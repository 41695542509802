<template>
  <el-table
    v-loading="loading"
    style="width: 100%"
    ref="table"
    :data="tableDataPlace"
    height="350px"
    :row-style="{ height: '0' }"
    :cell-style="{ padding: '3px' }"
    highlight-parcelItem-row
    border
    :header-cell-style="{
      background: '#4f81bd',
      color: '#fff',
      padding: '0 0',
    }"
  >
    <!--子表实现 -->
    <!-- <el-table-column type="expand">
      <template v-slot="slot">
        <el-table
          :data="slot.row.surnameModels"
          style="width: 90%; margin: 0 auto"
          ref="table"
          :header-cell-style="{ background: '#dcdfe6' }"
        >
          <el-table-column label="姓氏" prop="surname"></el-table-column>
          <el-table-column label="总数" sortable prop="count"></el-table-column>
        </el-table>
      </template>
    </el-table-column> -->
    <el-table-column
      label="迁出地"
      prop="beginPlace"
      :show-overflow-tooltip="true"
    ></el-table-column>
    <el-table-column label="总量" sortable prop="count"></el-table-column>
    <el-table-column label="统计图表">
      <template slot-scope="scope">
        <el-button size="mini" type="text" @click="handleEdit(scope.row)"
          >查看</el-button
        >
        <!-- <el-button
          size="mini"
          type="text"
          @click="handleDelete(scope.$index, scope.row)"
          >柱状图</el-button
        > -->
      </template>
    </el-table-column>
  </el-table>
</template>
  
  <script>
export default {
  props: {
    tableDataPlace: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      name: "",
      count: 0,
    };
  },
  methods: {
    handleEdit(data) {
      if (this.count === data.count && this.name === data.beginPlace) {
        this.count = "";
        this.name = "";
        this.$emit("viewPlace", []);
      } else {
        this.count = data.count;
        this.name = data.beginPlace;
        this.$emit("viewPlace", data.surnameModels);
      }
    },
  },
};
</script>
  
  <style>
</style>