<template>
  <div class="container">
    <drillingIn></drillingIn>
  </div>
</template>

<script>
import drillingIn from "../../components/drillingIn";
export default {
  components: {
    drillingIn,
  },
};
</script>

<style scoped>
.container {
  background: #eee;
  height: auto;
}
</style>