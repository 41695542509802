<template>
  <el-table
    :data="tableData"
    v-loading="loading"
    height="350px"
    :row-style="{ height: '0' }"
    :cell-style="{ padding: '3px' }"
    highlight-parcelItem-row
    border
    :header-cell-style="{
      background: '#4f81bd',
      color: '#fff',
      padding: '0 0',
    }"
  >
    <!--子表实现 -->
    <!-- <el-table-column type="expand">
      <template v-slot="slot">
        <el-table
          :data="slot.row.beginPlaceModels"
          style="width: 90%; margin: 0 auto"
          ref="table"
          :header-cell-style="{ background: '#dcdfe6' }"
        >
          <el-table-column label="迁出地" prop="beginPlace"></el-table-column>
          <el-table-column label="总数" sortable prop="count"></el-table-column>
        </el-table>
      </template> 
    </el-table-column>-->
    <el-table-column label="姓氏" prop="surname"></el-table-column>
    <el-table-column label="总量" sortable prop="count"></el-table-column>
    <el-table-column label="统计图表">
      <template slot-scope="scope">
        <el-button size="mini" type="text" @click="handleEdit(scope.row)"
          >查看</el-button
        >
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  props: {
    tableData: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      name: "",
      count: 0,
    };
  },
  methods: {
    handleEdit(data) {
      // if (this.count === data.count && this.name === data.surname) {
      //   this.count = "";
      //   this.name = "";
      //   this.$emit("viewSurname", []);
      // } else {
      this.count = data.count;
      this.name = data.surname;
      this.$emit("viewSurname", data.beginPlaceModels, data.surname);
      // }
    },
  },
};
</script>

<style>
</style>