<template>
  <div class="container" ref="container">
    <div class="box">
      <div class="left">
        <div class="top">
          <img src="../../assets/数据圆饼图.png" alt="" />
          <span>各县市迁徙数据统计</span>
        </div>
        <ul class="mapName">
          <li v-for="(v, i) in mapNameList" :key="v">
            <span>{{ v }}</span>
            <span slot="" v-show="i + 1 != mapNameList.length">></span>
          </li>
        </ul>
        <ul class="middle">
          <li
            class="item"
            v-for="v in areaList"
            :key="v"
            @click="placeClick(v)"
          >
            <img src="../../assets/三角形.png" alt="" />
            <span>{{ v }}</span>
          </li>
        </ul>
        <div class="bottom right">
          <div class="table" style="width: 100%">
            <div class="two">
              <div class="title">
                <div
                  :class="btnActive2 === 1 ? 'active' : ''"
                  @click="btnClick1(1)"
                >
                  已对接
                  <span style="font-size: 14px" v-if="btnActive2 === 1"
                    >({{ countrySameNameInfo.length }})</span
                  >
                </div>
                <div
                  :class="btnActive2 === 2 ? 'active' : ''"
                  @click="btnClick1(2)"
                >
                  待对接
                  <span style="font-size: 14px" v-if="btnActive2 === 2"
                    >({{ countrySameNameInfo.length }})</span
                  >
                </div>
                <div
                  :class="btnActive2 === 3 ? 'active' : ''"
                  @click="btnClick1(3)"
                >
                  待考据
                  <span style="font-size: 14px" v-if="btnActive2 === 3"
                    >({{ countrySameNameInfo.length }})</span
                  >
                </div>
                <div
                  :class="btnActive2 === 4 ? 'active' : ''"
                  @click="btnClick1(4)"
                >
                  不明确
                  <span style="font-size: 14px" v-if="btnActive2 === 4"
                    >({{ countrySameNameInfo.length }})</span
                  >
                </div>
              </div>
              <div>
                <el-table
                  v-loading="loading2"
                  height="290px"
                  :row-style="{ height: '0' }"
                  :cell-style="{ padding: '3px' }"
                  :data="countrySameNameInfo"
                  highlight-parcelItem-row
                  border
                  :header-cell-style="{ background: '#4f81bd', color: '#fff' }"
                >
                  <!-- <el-table-column type="index" :index="indexMethod">
                  </el-table-column> -->
                  <el-table-column
                    label="姓氏"
                    prop="surname"
                    width="60px"
                  ></el-table-column>
                  <el-table-column
                    label="福建"
                    prop="fuJian"
                    :show-overflow-tooltip="true"
                  ></el-table-column>
                  <el-table-column label="台湾" :show-overflow-tooltip="true">
                    <template slot-scope="scope">
                      <span
                        style="cursor: pointer"
                        @click="goToThePlace(scope.row)"
                        >{{ scope.row.taiWan }}</span
                      >
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </div>
          <!-- <div class="areaEchart" ref="areaEchart"></div>
          <p v-show="thermodynamicsList.length">台湾地区迁入地人数统计图</p> -->
        </div>
      </div>
      <div class="right">
        <div class="map">
          <div ref="echart" class="echartMap"></div>
          <div
            class="button"
            :disabled="mapNameList.length === 1"
            @click="goBack"
          >
            上一级
          </div>
        </div>
        <div class="table">
          <div>
            <div style="height: 50px; display: flex; align-items: center">
              <el-select v-model="searchType" placeholder="请选择">
                <el-option
                  v-for="item in typeOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <el-input
                v-model="searchValue"
                placeholder="请输入"
                @keyup.enter.native="onSearch"
              ></el-input>
              <el-button icon="el-icon-search" @click="onSearch"></el-button>
            </div>

            <el-table
              v-loading="loading"
              height="350px"
              :row-style="{ height: '0' }"
              :cell-style="{ padding: '3px' }"
              :data="listInfo"
              highlight-parcelItem-row
              border
              :header-cell-style="{ background: '#4f81bd', color: '#fff' }"
            >
              <el-table-column
                label="姓氏"
                prop="surName"
                width="70px"
              ></el-table-column>
              <el-table-column
                label="迁出地"
                prop="beginPlaceAddress"
                :show-overflow-tooltip="true"
              ></el-table-column>
              <el-table-column
                label="迁入地"
                prop="endPlaceAddress"
                :show-overflow-tooltip="true"
              ></el-table-column>
              <!-- <el-table-column
                label="迁徙记录"
                prop="sourceText"
                :show-overflow-tooltip="true"
              ></el-table-column> -->
              <el-table-column label="迁徙记录" prop="sourceText">
                <template slot-scope="scope">
                  <el-popover placement="top-start" width="400" trigger="hover">
                    <span>{{ scope.row.sourceText }}</span>
                    <span slot="reference" v-show="scope.row.sourceText">{{
                      scope.row.sourceText.substr(0, 10) + "..."
                    }}</span>
                  </el-popover>
                </template>
              </el-table-column>
              <el-table-column label="查看详情" width="100px">
                <template slot-scope="scope">
                  <el-button
                    size="mini"
                    type="text"
                    @click="viewInfo(scope.row)"
                    >详情</el-button
                  >
                  <el-button size="mini" type="text" @click="viewPic(scope.row)"
                    >原文</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
            <el-pagination
              class="toppage"
              @current-change="handleCurrentChange"
              :current-page.sync="pageIndex"
              layout="total,prev, pager, next"
              :total="total"
            >
            </el-pagination>
          </div>
          <div class="two" style="flex: 1">
            <div class="title">
              <div
                v-if="mapNameList.length > 1"
                :class="btnActive === 0 ? 'active' : ''"
                @click="btnClick(0)"
              >
                详情
              </div>
              <div
                :class="btnActive === 1 ? 'active' : ''"
                @click="btnClick(1)"
              >
                姓氏
              </div>
              <div
                :class="btnActive === 2 ? 'active' : ''"
                @click="btnClick(2)"
              >
                地区
              </div>
              <!-- <div
                :class="btnActive === 3 ? 'active' : ''"
                @click="btnClick(3)"
              >
                人口分布
              </div> -->
            </div>
            <div class="name_place" v-show="btnActive >= 1 && btnActive !== 3">
              <div class="left">
                <surname
                  :loading="loading1"
                  v-if="btnActive === 1"
                  :tableData="tableData"
                  @viewSurname="viewSurname"
                >
                </surname>
                <place
                  v-else
                  :loading="loading1"
                  :tableDataPlace="tableDataPlace"
                  @viewPlace="viewPlace"
                >
                </place>
                <el-pagination
                  class="toppage"
                  @current-change="handleCurrentChange1"
                  :current-page.sync="pageIndex1"
                  layout="total,prev, pager, next"
                  :total="total1"
                  small
                >
                </el-pagination>
              </div>
              <div class="right">
                <div class="surnameEch" ref="surnameEch"></div>
                <span v-if="isPlaceName"> 地区姓氏统计图 </span>
                <span v-else>{{
                  btnActive == 1 ? "迁徙姓氏统计图" : "迁徙地区统计图"
                }}</span>
              </div>
            </div>
            <div class="test">
              <div class="name_place name_place1" v-if="btnActive === 0">
                <div
                  style="
                    padding: 5px 0;
                    height: 50px;
                    position: sticky;
                    top: 0;
                    background-color: #fff;
                  "
                >
                  <el-input
                    v-model="input"
                    placeholder="请输入内容"
                    style="width: 200px"
                  ></el-input
                  ><el-button type="primary">查询</el-button>

                  <span
                    v-show="infoByAreaObj.countrySameName"
                    style="
                      font-family: Tahoma, Helvetica, Arial, ‘宋体’, sans-serif;

                      line-height: 140%;
                      font-weight: 700;
                      margin-left: 20px;
                    "
                    >同名同宗村：{{ infoByAreaObj.countrySameName }}</span
                  >
                </div>

                <table>
                  <!--
                  <tr v-show="infoByAreaObj.countyCityName">
                    <td class="td1">县市名称</td>
                    <td class="td2">{{ infoByAreaObj.countyCityName }}</td>
                  </tr>
                  <tr v-show="infoByAreaObj.districtTownshipName">
                    <td class="td1">区、乡镇名称</td>
                    <td class="td2">{{ infoByAreaObj.districtTownshipName }}</td>
                  </tr>
                  <tr v-show="infoByAreaObj.villageName">
                    <td class="td1">村里名称</td>
                    <td class="td2">{{ infoByAreaObj.villageName }}</td>
                  </tr>
                  -->
                  <tr v-show="infoByAreaObj.area">
                    <td class="td1">行政区划</td>
                    <td class="td2">{{ infoByAreaObj.area }}</td>
                  </tr>
                  <!--
  
                    <tr v-show="infoByAreaObj.governmentOrgans">
                      <td class="td1">政府机构</td>
                      <td class="td2">{{ infoByAreaObj.governmentOrgans }}</td>
                    </tr>
                    <tr v-show="infoByAreaObj.countyMayor">
                      <td class="td1">县市长、区、乡镇长</td>
                      <td class="td2">{{ infoByAreaObj.countyMayor }}</td>
                    </tr>
                    <tr v-if="infoByAreaObj.curPersonInfoDto">
                      <td class="td1">职位</td>
                      <td class="td2">
                        {{ infoByAreaObj.curPersonInfoDto.professionalTitle }}
                      </td>
                    </tr>
                  -->
                  <tr v-if="infoByAreaObj.curPersonInfoDto">
                    <td class="td1">负责人</td>
                    <td class="td2">
                      <span
                        @click="personInfo(infoByAreaObj.curPersonInfoDto.id)"
                      >
                        {{ infoByAreaObj.curPersonInfoDto.personName }}({{
                          infoByAreaObj.curPersonInfoDto.professionalTitle
                        }})</span
                      >
                    </td>
                  </tr>

                  <tr
                    v-show="
                      infoByAreaObj.historyPersonList &&
                      infoByAreaObj.historyPersonList.length
                    "
                  >
                    <td class="td1">历届负责人</td>
                    <td class="td2">
                      <span
                        v-for="v in infoByAreaObj.historyPersonList"
                        :key="v.id"
                        @click="personInfo(v.id)"
                      >
                        {{ v.electYear }}{{ v.electYear ? ":" : ""
                        }}{{ v.personName }}</span
                      >
                    </td>
                  </tr>
                  <tr v-show="infoByAreaObj.remark">
                    <td class="td1">简介</td>
                    <td class="td2">{{ infoByAreaObj.remark }}</td>
                  </tr>
                  <tr v-show="infoByAreaObj.localHistories">
                    <td class="td1">志书、乡镇志</td>
                    <td class="td2">{{ infoByAreaObj.localHistories }}</td>
                  </tr>
                  <tr v-show="infoByAreaObj.genealogyInfo">
                    <td class="td1">地区谱牒</td>
                    <td class="td2">{{ infoByAreaObj.genealogyInfo }}</td>
                  </tr>
                  <tr v-show="infoByAreaObj.population">
                    <td class="td1">人口数</td>
                    <td class="td2">{{ infoByAreaObj.population }}</td>
                  </tr>
                  <tr v-show="infoByAreaObj.populationDensity">
                    <td class="td1">人口密度</td>
                    <td class="td2">{{ infoByAreaObj.populationDensity }}</td>
                  </tr>
                  <tr v-show="infoByAreaObj.populationProfile">
                    <td class="td1">人口概括</td>
                    <td class="td2">{{ infoByAreaObj.populationProfile }}</td>
                  </tr>
                  <tr v-show="infoByAreaObj.trafficProfile">
                    <td class="td1">交通概述</td>
                    <td class="td2">{{ infoByAreaObj.trafficProfile }}</td>
                  </tr>
                  <tr v-show="infoByAreaObj.villagesNumber">
                    <td class="td1">村里数</td>
                    <td class="td2">{{ infoByAreaObj.villagesNumber }}</td>
                  </tr>
                  <tr v-show="infoByAreaObj.neighborNumber">
                    <td class="td1">鄰数</td>
                    <td class="td2">{{ infoByAreaObj.neighborNumber }}</td>
                  </tr>
                  <tr v-show="infoByAreaObj.households">
                    <td class="td1">户数</td>
                    <td class="td2">{{ infoByAreaObj.households }}</td>
                  </tr>
                  <tr v-show="infoByAreaObj.landArea">
                    <td class="td1">土地面积</td>
                    <td class="td2">{{ infoByAreaObj.landArea }}</td>
                  </tr>
                  <tr v-show="infoByAreaObj.villagesResource">
                    <td class="td1">村名由來</td>
                    <td
                      class="td2"
                      v-html="infoByAreaObj.villagesResource"
                    ></td>
                  </tr>
                  <tr v-show="infoByAreaObj.historicalEvolution">
                    <td class="td1">历史沿革</td>
                    <td class="td2">{{ infoByAreaObj.historicalEvolution }}</td>
                  </tr>
                  <tr v-show="infoByAreaObj.geographicLocation">
                    <td class="td1">地理位置</td>
                    <td class="td2">{{ infoByAreaObj.geographicLocation }}</td>
                  </tr>
                  <tr v-show="infoByAreaObj.geographicEnvironment">
                    <td class="td1">地理环境</td>
                    <td class="td2">
                      {{ infoByAreaObj.geographicEnvironment }}
                    </td>
                  </tr>
                  <tr v-show="infoByAreaObj.climateRemak">
                    <td class="td1">气候概括</td>
                    <td class="td2">{{ infoByAreaObj.climateRemak }}</td>
                  </tr>
                  <tr v-show="infoByAreaObj.specialtyName">
                    <td class="td1">特产名称</td>
                    <td class="td2">{{ infoByAreaObj.specialtyName }}</td>
                  </tr>
                  <tr v-show="infoByAreaObj.placesHistoric">
                    <td class="td1">名胜古迹</td>
                    <td class="td2">{{ infoByAreaObj.placesHistoric }}</td>
                  </tr>
                  <tr v-show="infoByAreaObj.folkFestivals">
                    <td class="td1">民俗节庆</td>
                    <td class="td2">{{ infoByAreaObj.folkFestivals }}</td>
                  </tr>
                  <tr v-show="infoByAreaObj.scenicSpot">
                    <td class="td1">风景区</td>
                    <td class="td2">{{ infoByAreaObj.scenicSpot }}</td>
                  </tr>
                  <tr v-show="infoByAreaObj.localCharacteristicMuseum">
                    <td class="td1">地方特色馆</td>
                    <td class="td2">
                      {{ infoByAreaObj.localCharacteristicMuseum }}
                    </td>
                  </tr>
                  <tr v-show="infoByAreaObj.emblem">
                    <td class="td1">徽</td>
                    <td class="td2">{{ infoByAreaObj.emblem }}</td>
                  </tr>
                  <tr v-show="infoByAreaObj.tree">
                    <td class="td1">树</td>
                    <td class="td2">{{ infoByAreaObj.tree }}</td>
                  </tr>
                  <tr v-show="infoByAreaObj.flower">
                    <td class="td1">花</td>
                    <td class="td2">{{ infoByAreaObj.flower }}</td>
                  </tr>
                  <tr v-show="infoByAreaObj.bird">
                    <td class="td1">鸟</td>
                    <td class="td2">{{ infoByAreaObj.bird }}</td>
                  </tr>
                  <tr v-show="infoByAreaObj.logoDescription">
                    <td class="td1">标志说明</td>
                    <td class="td2">{{ infoByAreaObj.logoDescription }}</td>
                  </tr>
                  <tr v-show="infoByAreaObj.topographyGeology">
                    <td class="td1">地形地质</td>
                    <td class="td2">{{ infoByAreaObj.topographyGeology }}</td>
                  </tr>
                  <tr v-show="infoByAreaObj.industryOverview">
                    <td class="td1">产业概况</td>
                    <td class="td2">{{ infoByAreaObj.industryOverview }}</td>
                  </tr>
                  <tr v-show="infoByAreaObj.localReportingInfo">
                    <td class="td1">地方报道信息</td>
                    <td class="td2">{{ infoByAreaObj.localReportingInfo }}</td>
                  </tr>
                  <tr v-show="infoByAreaObj.humanisticHistory">
                    <td class="td1">人文历史</td>
                    <td class="td2">{{ infoByAreaObj.humanisticHistory }}</td>
                  </tr>
                  <tr v-show="infoByAreaObj.localCharacteristics">
                    <td class="td1">地方特色</td>
                    <td class="td2">
                      {{ infoByAreaObj.localCharacteristics }}
                    </td>
                  </tr>
                </table>
              </div>
              <div class="name_place name_place1" v-if="btnActive === 3">
                <img
                  :src="imgUrl"
                  style="max-height: 100%; max-width: 100%"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <myMap
      v-if="mapDialog"
      :mapDialog.sync="mapDialog"
      ref="map"
      :migrationPointsList="migrationPointsList"
      :allPointsList="allPointsList"
    ></myMap>
    <el-dialog
      class="surnameDialog"
      title="详情"
      :visible.sync="dialogVisible"
      width="80%"
      height="50%"
      :close-on-click-modal="false"
      @close="dialogClose"
    >
      <div class="echartBox">
        <div class="echart" :id="'echart' + v" v-for="v in num" :key="v"></div>
      </div>
    </el-dialog>
    <!-- 原文 -->
    <el-dialog
      id="dlog"
      :visible.sync="isPicShow"
      style="height: 90vh"
      @close="isPicShow = false"
      width="70%"
      title="原文摘录"
    >
      <div class="dialog_box">
        <div class="left" v-dragscroll>
          <el-image
            v-resize
            @load="onload"
            @error="onerror"
            :src="`/api/fileinfo/image/GetOriImage?filename=${pictureFullName}&ocrSource=1`"
            style="
               {
                height: 100%;
                transform-origin: center top;
              }
            "
            fit="cover"
          />
        </div>
        <div class="right" v-if="infoMrigObj">
          <p style="margin: 10px 0; font-size: 18px">
            <strong>名称：</strong><strong>{{ infoObj.title }}</strong>
          </p>
          <div
            style="
              font-size: 18px;
              line-height: 20px;
              display: flex;
              flex-wrap: wrap;
            "
          >
            <p style="margin: 10px 50 10px 0" v-if="infoObj.publisher">
              <span>编者：</span>
              <span>{{ infoObj.publisher }}</span>
            </p>
            <p
              style="margin: 10px 50px 10px 0"
              v-if="infoObj.dateOfPublication"
            >
              <span>出版日期：</span>
              <span>{{ infoObj.dateOfPublication }}</span>
            </p>
            <p style="margin: 10px 50px 10px 0" v-if="infoObj.copies">
              <span>卷册数：</span>
              <span>{{ infoObj.copies }}</span>
            </p>
            <p style="margin: 10px 50px 10px 0" v-if="infoObj.ancestor">
              <span>始祖：</span>
              <span>{{ infoObj.ancestor }}</span>
            </p>
            <p style="margin: 10px 50px 10px 0" v-if="infoObj.owner">
              <span>收藏单位：</span>
              <span>{{ infoObj.owner }}</span>
            </p>
          </div>
          <div style="margin: 10px 0; font-size: 18px">
            <p style="margin-bottom: 10px">迁徙记录：</p>
            <p style="margin-bottom: 10px; line-height: 20px">
              <i style="font-style: normal">{{ infoMrigObj.personalName }}：</i>
              <span
                infoMrigObj-if="infoMrigObj.migrateDate || infoMrigObj.migrateDateYear"
                >{{ infoMrigObj.migrateDate
                }}{{ infoMrigObj.migrateDateYear }})，</span
              >
              <span
                >{{ infoMrigObj.beginPlace.replace("#", "") }}（{{
                  infoMrigObj.beginPlaceAddress
                }}）</span
              >
              &nbsp;
              <img
                style="width: 18px; vertical-align: middle"
                src="../../assets//image/前往.png"
              />&nbsp;
              <span
                >{{ infoMrigObj.endPlace.replace("#", "") }}（{{
                  infoMrigObj.endPlaceAddress
                }}）</span
              >
            </p>
            <p style="margin-bottom: 10px">
              <span style="line-height: 25px"
                >迁徙信息：{{ infoMrigObj.sourceText }}</span
              >
            </p>
          </div>
        </div>
      </div>
      <div
        class="el-dialog__footer"
        style="display: flex; justify-content: center; align-items: center"
      >
        <el-button @click="Before">上一页</el-button>
        <el-button @click="After">下一页</el-button>
      </div>
    </el-dialog>

    <el-dialog
      v-dialogdrag
      :title="personInfoObj.personName"
      :visible.sync="personInfoDialog"
      width="70%"
      @close="personInfoDialog = false"
    >
      <div style="height: 600px; overflow: auto">
        <table>
          <tr v-show="personInfoObj.personName">
            <td class="td1">姓名</td>
            <td class="td2">{{ personInfoObj.personName }}</td>
          </tr>
          <tr v-show="personInfoObj.sex">
            <td class="td1">性别</td>
            <td class="td2">{{ personInfoObj.sex }}</td>
          </tr>
          <tr>
            <td class="td1">选举年</td>
            <td class="td2">{{ personInfoObj.electYear }}</td>
          </tr>
          <!-- <tr>
            <td class="td1">县市名称</td>
            <td class="td2">{{ personInfoObj.countyCityName }}</td>
          </tr> -->
          <tr v-show="personInfoObj.area">
            <td class="td1">行政区域</td>
            <td class="td2">{{ personInfoObj.area }}</td>
          </tr>
          <tr v-show="personInfoObj.governmentOrgans">
            <td class="td1">政府机构</td>
            <td class="td2">{{ personInfoObj.governmentOrgans }}</td>
          </tr>

          <tr v-show="personInfoObj.professionalTitle">
            <td class="td1">職稱</td>
            <td class="td2">{{ personInfoObj.professionalTitle }}</td>
          </tr>

          <tr v-show="personInfoObj.partyMembership">
            <td class="td1">黨籍</td>
            <td class="td2">{{ personInfoObj.partyMembership }}</td>
          </tr>
          <tr v-show="personInfoObj.officeAddress">
            <td class="td1">办公地址</td>
            <td class="td2">{{ personInfoObj.officeAddress }}</td>
          </tr>
          <tr v-show="personInfoObj.officePhoneNumber">
            <td class="td1">办公电话</td>
            <td class="td2">{{ personInfoObj.officePhoneNumber }}</td>
          </tr>
          <tr v-show="personInfoObj.email">
            <td class="td1">电子邮箱</td>
            <td class="td2">{{ personInfoObj.email }}</td>
          </tr>
          <tr v-show="personInfoObj.educationalBackground">
            <td class="td1">學歷</td>
            <td class="td2">{{ personInfoObj.educationalBackground }}</td>
          </tr>
          <tr v-show="personInfoObj.resume">
            <td class="td1">簡歷</td>
            <td class="td2">{{ personInfoObj.resume }}</td>
          </tr>
          <tr v-show="personInfoObj.blog">
            <td class="td1">部落格</td>
            <td class="td2">{{ personInfoObj.blog }}</td>
          </tr>
          <tr v-show="personInfoObj.webUrl">
            <td class="td1">連結網頁</td>
            <td class="td2">{{ personInfoObj.webUrl }}</td>
          </tr>
          <!-- <tr>
            <td class="td1">
              {{ personType === 0 ? "现担任者资料" : "历届担任者" }}
            </td>
          </tr> -->
        </table>
      </div>
    </el-dialog>

    <!--
    <div class="tisTop" ref="tisTop" v-if="tisTopShow">
     <div class="title" @mousedown="startDrag">
        <span>杨厝里</span>
        <span class="close" @click="onClo">X</span>
      </div>
      <div class="content">
        <p class="MsoNormal"><b>1</b><b>、基本信息</b><b></b></p>
        <p class="MsoNormal">
          <b>里長：</b>曾弘利<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
        </p>
        <p class="MsoNormal"><b>電話：</b><span>04-26203607</span></p>
        <p class="MsoNormal">
          <b>住址：</b
          >清水區楊厝里楊厝一街<span>51</span>巷<span>36</span>號<span></span>
        </p>
        <p class="MsoNormal">
          <b>里幹事：</b>王嘉宏<span
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </p>
        <p class="MsoNormal">
          <b>電話：</b><span>04-26270151</span>分機<span>219</span>
        </p>
        <p class="MsoNormal">
          <b><span>2</span></b
          ><b>、特色簡介：<span></span></b>
        </p>
        <p class="MsoNormal">
          楊厝里位於清水區行政區域東北隅，距市區約八公里路程東臨清泉岡空軍基地，西近鰲峰山公園，北與海風里為臨，南與吳厝里為界。面積<span>3.64</span>平方公里，居民大部分從事農業，為一典型農業社區，主要產品為蘿蔔、甘藷、花生、高梁及玉米等皆為旱糧作物，民風淳樸，克勤克儉，無工業污染，整片大地綠意盎然，空氣新鮮。<span
          ></span>
        </p>
        <p class="MsoNormal">
          本里目前有大楊國小計一所，它提供里民求學上進的好場所，在交通不發達、教育設備有限的情況下，里民亦能努力求學上進。加與位於清泉崗高爾夫球場旁，達官顯要進出頻繁，使本里呈現一片欣欣向榮景象。<span
          ></span>
        </p>
        <p class="MsoNormal">
          <b><span>3</span></b
          ><b>、楊厝里簡介：<span></span></b>
        </p>
        <p class="MsoNormal">
          <span>&nbsp;&nbsp;&nbsp; </span
          >楊厝里位於清水鎮行政區域東北隅，距市區約四公里路程，東臨清泉崗空軍基地，西近清水公園，北與海風里為臨，南與吳厝里為界，面積三<span>.</span>六四平方公里，住戶約有二六五戶，人口約一四五三人，居民大部份從事農業，為一典型農業社高，主要產品為蘿蔔、甘藷、花生、高梁及玉米等皆為旱糧作物，民風淳樸、克勤克儉，無工業河染，整片大地綠意盎然，空氣新鮮。<span
          ></span>
        </p>
        <p class="MsoNormal">
          <span>&nbsp;&nbsp;&nbsp; </span
          >至於楊厝里地名的由來，因昔日楊厝寮包括清水鎮楊厝、海風兩里，分布在大肚台地上。舊大字楊厝寮屬楊厝里。在清水街區東北方約四公里，距海風聚落的東南約一<span>.</span>一公里，面前厝聚落東南六五Ｏ公尺處，為大肚台地上的一個集村，海拔一九五至二ＯＯ公尺。本聚落因在民國四十五年間劃入清泉崗空軍基地，住民遷出已消滅，早在乾隆年間，即有福建省泉州府楊姓業戶來墾，至末葉已墾成。地名由來源於創村當時為楊姓的血緣聚落而得名。<span
          ></span>
        </p>
        <p class="MsoNormal">
          <span>&nbsp;&nbsp;&nbsp; </span
          >楊厝里係由昔日幾個部落所組成，其大致如下：<span></span>
        </p>
        <p class="MsoNormal">
          <span>&nbsp;&nbsp;&nbsp; </span
          >一、許厝寮：位於現今保安清宮東南邊約二ＯＯ公尺的集村，住民多從番仔城南遷者，因起初許姓幾占半數以上，由於許姓在此搭寮以居，故得稱。<span
          ></span>
        </p>
        <p class="MsoNormal">
          <span>&nbsp;&nbsp;&nbsp; </span
          >二、面前厝：在保安清宮一帶，往昔在番仔城前有一坑溝，因在其南邊築屋成村，故稱面前厝。昔日為雜姓住戶所居，多數從農，為甘蔗、甘藷、蘿蔔的產地。<span
          ></span>
        </p>
        <p class="MsoNormal">
          <span>&nbsp;&nbsp;&nbsp; </span
          >三、番仔城：在保安清宮之北邊約一七五公尺的集村。因原為平埔族的聚落故名。究竟是那一平埔族？是“大突社”屯番的洪雅族或大甲地方南下的道卡斯族，抑是清水方面之拍瀑拉族，不明。現墳內多雜姓住戶，以農為業，主要作物有甘藷、甘蔗、蘿蔔等。<span
          ></span>
        </p>
        <p class="MsoNormal">
          <span>&nbsp;&nbsp;&nbsp; </span
          >四、山尾：在保安清宮西南方六二五公尺處，海拔二ＯＯ至二Ｏ五公尺，因在楊厝聚落西南尾端處的孤立小聚落，故得稱，為一雜姓聚落，多數務農，為甘蔗、甘藷、蘿蔔產地。<span
          ></span>
        </p>
        <p class="MsoNormal">
          <span>&nbsp;&nbsp;&nbsp; </span
          >五、堀後：在楊厝聚落的西北西方八ＯＯ公尺，東南東距海風六一五公尺處，海拔一八Ｏ至一八五公尺。因有一大水堀，聚落建在其後邊，故得稱。堀後係一多姓氏孤立小集村，居民以農為業。<span
          ></span>
        </p>
        <p class="MsoNormal">
          <span>&nbsp;&nbsp;&nbsp; </span
          >以上五聚落，是構成楊厝里主要行政區域範圍。<span></span>
        </p>
        <p class="MsoNormal">
          <span>&nbsp;&nbsp;&nbsp; </span
          >現今楊厝里主要廟宇有一保安清宮：供奉的主神為保安尊王，為許姓後代子孫所膜拜。二一三元宮：供奉的主神為三太子，為里民共同信仰寄託之所，香火鼎盛，香客遍佈全省各地。三忠義堂：供俸的主神為關公，為里內曾、蔡兩姓後代子孫所主持，香火鼎盛。還有數處神壇及一處土地公廟，皆為里民心靈寄託之所。楊厝里住戶雖不多，但廟宇與神壇非常普及，民間信仰非常篤實，里內呈現一片祥和之氣。里民原從事農業生產為主要收入，近年來由於曾芳玉香舖行，在全省闖出名號，里民敬相效法，從事線香製造與銀紙製造，使該項行業成為里民主要收入，也成為全省從事線香與銀紙製造的重鎮。<span
          ></span>
        </p>
        <p class="MsoNormal">
          <span>&nbsp;&nbsp;&nbsp; </span
          >楊厝里白從實施地方自治以來，即維持九鄰編制，人民從事農業生產為主，人口數增加緩慢。但位居清泉崗高爾夫球場旁繁榮可期。<span
          ></span>
        </p>
        <p class="MsoNormal">
          <b><span>4</span></b
          ><b>、歷屆里長：<span></span></b>
        </p>
        <p class="MsoNormal">第一屆：許南 蔡其石<span></span></p>
        <p class="MsoNormal">第二屆：許南 楊鵬<span></span></p>
        <p class="MsoNormal">第三屆：蔡其石<span></span></p>
        <p class="MsoNormal">第四屆：蔡其石<span></span></p>
        <p class="MsoNormal">第五屆：蔡其石<span></span></p>
        <p class="MsoNormal">第六屆：許南<span></span></p>
        <p class="MsoNormal">第七屆：曾金杯<span></span></p>
        <p class="MsoNormal">第八屆：曾百祿<span></span></p>
        <p class="MsoNormal">第九屆：曾百祿<span></span></p>
        <p class="MsoNormal">第十屆：曾金治<span></span></p>
        <p class="MsoNormal">第十一屆：陳炎芳<span></span></p>
        <p class="MsoNormal">第十二屆：陳炎芳<span></span></p>
        <p class="MsoNormal">第十三屆：曾金治<span></span></p>
        <p class="MsoNormal">第十四屆：曾金治<span></span></p>
        <p class="MsoNormal">第十五屆：曾金治<span></span></p>
        <p class="MsoNormal">第十六屆：曾金治<span></span></p>
        <p class="MsoNormal">现任：曾弘利<span></span></p>
        <p class="MsoNormal">
          <span></span>
        </p>
        <p class="MsoNormal">
          <span></span>
        </p>

        <p>
          <br />
        </p>
      </div>
    </div>-->
  </div>
</template>

<script>
import * as echarts from "echarts";
import {
  getMap,
  getSurNameCount,
  getBeginPlace,
  getCount,
  placeAndSurname,
  getCountryInfos,
  getCountrySameNameInfos,
  getInfoByArea,
  getPersonInfo,
} from "../../api/drillingIn";
import taiWan from "../../../public/map/台湾省.json";
import surname from "@/components/drillingIn/components/surname";
import place from "./components/place.vue";
import { getMigrate, BeforeOrNext, getDetails } from "@/api/susername";
import myMap from "./components/map.vue";
import { Loading } from "element-ui";
export default {
  data() {
    return {
      imgUrl: require("../../assets/bg.jpg"),
      personInfoObj: {},
      personInfoDialog: false,
      infoByAreaObj: {},
      // regular: false,
      isDragging: false,
      offsetX: "",
      offsetY: "",
      left: 0,
      top: 0,
      // tisTopShow: false,
      isPlaceName: false,
      infoObj: null,
      infoMrigObj: null,
      genealogyOCRInfoID: "",
      loadingInstance: "",
      options: {
        fullscreen: true,
        lock: true,
        text: "Loading",
        target: ".el-dialog__body",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      },
      isPicShow: false,
      pictureFullName: "",
      dialogSurname: "",
      num: 1,
      dialogVisible: false,
      areaEchart: null,
      surnameEchChart: null,
      areaDom: null,
      surnameEchDom: null,
      thermodynamicsList: [],
      loading: false,
      loading1: false,
      select: "1",
      input: "",
      name: "",
      chart: null,
      mapIndex: 0,
      mapNameList: ["台湾省"],
      currentMapName: "",
      pieChart: null,
      btnActive: 1,
      btnActive2: 1,
      tableData: [],
      tableDataPlace: [],
      areaList: [],
      pageIndex: 1,
      pageSize: 10,
      total: 0,
      pageIndex1: 1,
      pageSize1: 9,
      total1: 0,
      listInfo: [],
      countrySameNameInfo: [],
      loading2: false,
      allPointsList: [],
      migrationPointsList: [],
      mapDialog: false,
      isVillage: false,
      villageName: "",
      searchType: "SearchAll",
      searchValue: "",
      typeOptions: [
        { value: "Surname", label: "姓氏" },
        { value: "RelatedPersons", label: "迁徙人物" },
        { value: "EndPlace", label: "迁入地" },
        { value: "BeginPlace", label: "迁出地" },
        { value: "MigrateText", label: "迁徙记录" },
        { value: "MigrateDate", label: "迁徙时间" },
        { value: "SearchAll", label: "全部" },
      ],
      countryShow: false,
      countryInfos: {},
    };
  },
  components: {
    surname,
    place,
    myMap,
  },
  async mounted() {
    this.surnameEchDom = this.$refs.surnameEch;
    // this.areaDom = this.$refs.areaEchart;
    this.areaList = [...new Set(taiWan.features.map((v) => v.properties.name))];
    this.getSurNameCount("台湾省");
    this.getMigrate();
    await this.getCount("台湾省", 1);
    this.init();
    this.chart.on("click", this.mapClick);
    // this.chart.on("mouseover", this.onMouseOver);
    // this.chart.on("mouseout", this.onMouseOut);
    await this.GetCountrySameNameInfo(1);
  },
  methods: {
    async goToThePlace(row) {
      let searchObj = {
        Surname: row.surname,
        BeginPlace: row.fuJian,
      };
      const tempArr = row.taiWan.split("/");
      // 判断是否到村
      this.isVillage = false;
      this.villageName = "";
      this.pageIndex = 1;
      if (tempArr.length === 4) {
        this.btnActive = 0;
        this.mapIndex = 2;
        // this.isVillage = true;
        // this.villageName = tempArr[tempArr.length - 1];
        this.mapNameList = tempArr.splice(0, tempArr.length - 1);
        let jsonUrl = `${window.location.origin}/map/${this.mapNameList.join(
          ""
        )}.json`;
        try {
          //去掉村里名包含阿拉伯数字的--开始
          let temGgeoData = await getMap(jsonUrl);
          let temFeatures = [];
          var regex = /[0-9]/;
          temGgeoData.features.forEach((tItem) => {
            if (!regex.test(tItem.properties.V_Name)) {
              temFeatures.push(tItem);
            }
          });
          let geoData = temGgeoData;
          geoData.features = temFeatures;
          //去掉村里名包含阿拉伯数字的--结束
          this.areaList = [
            ...new Set(geoData.features.map((v) => v.properties.name)),
          ];
          // await this.btnClick(this.btnActive);

          // this.getMigrate();
          await this.getCount(
            this.mapNameList.join("/"),
            this.mapNameList.length
          );
          echarts.registerMap(
            this.mapNameList[this.mapNameList.length - 1],
            geoData
          );
          let option = this.loadMap(
            this.mapNameList[this.mapNameList.length - 1],
            this.thermodynamicsList
          );

          // 村高亮
          for (let i = 0; i < option.series[0].data.length; i++) {
            if (option.series[0].data[i].name === tempArr[tempArr.length - 1]) {
              // 找到目标项后添加属性
              option.series[0].data[i].selected = true;
              break; // 停止循环
            }
          }

          await this.chart.setOption(option, true);

          this.chart.hideLoading();
          window.addEventListener("resize", function () {
            myChart.resize();
          });
        } catch (error) {
          console.log(error);
          this.mapIndex--;
        }
      } else if (tempArr.length < 4 && tempArr.length > 1) {
        this.mapIndex = tempArr.length - 2;
        this.mapNameList = tempArr.splice(0, tempArr.length - 1);
      } else {
        this.mapIndex = 0;
        this.btnActive = 1;
        this.areaList = [
          ...new Set(taiWan.features.map((v) => v.properties.name)),
        ];
        this.getSurNameCount("台湾省");
        this.getMigrate("", false, searchObj);
        await this.getCount("台湾省", 1);
        this.init();
        return;
      }

      this.mapClick({ name: tempArr[tempArr.length - 1] }, "go", searchObj);
    },
    async personInfo(id) {
      try {
        const res = await getPersonInfo(id);
        this.personInfoObj = res;
        this.personInfoDialog = true;
      } catch (error) {
        console.log(error);
      }
    },
    indexMethod(index) {
      return index + 1;
    },
    async GetCountrySameNameInfo(ttype) {
      this.loading2 = true;
      try {
        const res = await getCountrySameNameInfos({ type: ttype });
        this.countrySameNameInfo = res;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading2 = false;
      }
    },
    onSearch() {
      this.pageIndex = 1;
      this.getMigrate(this.mapNameList.join("/"), true);
    },
    // onClo() {
    //   this.tisTopShow = false;
    //   this.regular = false;
    // },
    // startDrag(e) {
    //   this.isDragging = true;
    //   this.offsetX = e.clientX - this.$refs.tisTop.getBoundingClientRect().left;
    //   this.offsetY = e.clientY - this.$refs.tisTop.getBoundingClientRect().top;

    //   document.addEventListener("mousemove", this.drag);
    //   document.addEventListener("mouseup", this.stopDrag);
    // },
    // drag(e) {
    //   if (this.isDragging) {
    //     const bigDivRect = this.$refs.container.getBoundingClientRect();
    //     const smallDiv = this.$refs.tisTop;

    //     let x = e.clientX - this.offsetX - bigDivRect.left;
    //     let y = e.clientY - this.offsetY - bigDivRect.top;

    //     // 确保小的div不会超出大的div
    //     x = Math.max(0, Math.min(x, bigDivRect.width - smallDiv.clientWidth));
    //     y = Math.max(0, Math.min(y, bigDivRect.height - smallDiv.clientHeight));

    //     smallDiv.style.left = x + "px";
    //     smallDiv.style.top = y + 50 + "px";
    //   }
    // },
    // stopDrag() {
    //   this.isDragging = false;
    //   document.removeEventListener("mousemove", this.drag);
    //   document.removeEventListener("mouseup", this.stopDrag);
    // },
    // onMouseOut() {
    //   if (this.regular) return;
    //   this.tisTopShow = false;
    // },
    // onMouseOver(e) {
    //   if (e.name === "杨厝里") {
    //     this.tisTopShow = true;
    //   }
    // },
    onload() {
      this.$nextTick(() => {
        if (this.loadingInstance) {
          // 以服务的方式调用的 Loading 需要异步关闭
          this.loadingInstance.close();
        }
      });
    },
    onerror() {
      this.$nextTick(() => {
        if (this.loadingInstance) {
          // 以服务的方式调用的 Loading 需要异步关闭
          this.loadingInstance.close();
        }
      });
    },
    async Before() {
      this.loadingInstance = Loading.service(this.options);
      try {
        const res = await BeforeOrNext({
          id: this.genealogyOCRInfoID,
          opType: 0,
        });
        // console.log(res, 'res')
        this.pictureFullName = res.pictureFullName;
        this.genealogyOCRInfoID = res.genealogyOCRInfoID
          .replace("；", ";")
          .split(";")[0];
      } catch (e) {
        if (e.response.status === "403") {
          this.$message.info("暂无数据");
        }
        this.loadingInstance.close();
      }
    },
    async After() {
      this.loadingInstance = Loading.service(this.options);
      try {
        const res = await BeforeOrNext({
          id: this.genealogyOCRInfoID,
          opType: 1,
        });
        this.genealogyOCRInfoID = res.genealogyOCRInfoID
          .replace("；", ";")
          .split(";")[0];
        this.pictureFullName = res.pictureFullName;
      } catch (e) {
        if (e.response.status === "403") {
          this.$message.info("暂无数据");
        }
        this.loadingInstance.close();
      }
    },
    init() {
      const echartMapDom = this.$refs.echart;
      if (echartMapDom) {
        this.chart = echarts.init(echartMapDom);
        this.chart.showLoading();
        this.currentMapName = "台湾省";
        echarts.registerMap("台湾省", taiWan);

        let option = this.loadMap("台湾省", this.thermodynamicsList);
        this.chart.setOption(option, true);
        this.chart.hideLoading();
        window.addEventListener("resize", function () {
          myChart.resize();
        });
      }
    },
    loadMap(name, dataList) {
      return {
        title: {
          left: "center",
          textStyle: {
            color: "#fff",
            fontSize: 30,
          },
          subtextStyle: {
            fontSize: 20,
          },
        },

        // 热力地图
        visualMap: {
          min: 0,
          max: 1000,
          left: -2000, // 不显示热力统计图
          textStyle: {
            color: "#000",
          },
          pieces: [
            {
              gte: 500,
              label: "> 500 人",
              color: "#FF6347",
            },
            {
              gte: 400,
              lte: 500,
              label: "400 - 500 人",
              color: "#FF8C00",
            },
            {
              gte: 300,
              lte: 400,
              label: "300 - 400 人",
              color: "#FFA500",
            },
            {
              gte: 100,
              lte: 300,
              label: "100 - 300 人",
              color: "#FFD700",
            },
            {
              gte: 1,
              lt: 100,
              label: "1 - 100 人",
              color: "#FFE4B5",
            },
          ],
        },

        series: [
          {
            data: dataList.map((item) => ({
              name: item.placeName,
              value: item.count,
            })),
            type: "map",
            map: name,
            zoom: 1.2,
            roam: true,
            aspectScale: 0.75,
            // 默认文本标签样式
            label: {
              offset: [0, 0], //偏移设置
              color: "#000",
              show: true,
            },
            itemStyle: {
              areaColor: "#FFF5E1",
              borderColor: "rgba(39,211,233, 1)",
              borderWidth: 1,
            },
            emphasis: {
              // 高亮文本标签样式
              label: {
                color: "#000",
                fontSize: 22,
                fontWeight: "bold",
              },
              // 高亮区域背景色
              itemStyle: {
                areaColor: "#01ADF2",
              },
            },
            // 点击选择地图高亮颜色
            select: {
              itemStyle: {
                color: "#01adf2",
              },
            },
          },
        ],
      };
    },
    async mapClick(params, status, searchObj = null,gobackFlag) {
      this.mapIndex = this.mapNameList.length - 1
      if (this.mapNameList.length === 4) {
        this.mapNameList.pop();
      }
      let flag = true;
      this.pageIndex = 1;
      if (params.name.includes("(海)")) return;
      if (this.searchType == "EndPlace") {
        this.searchType = "SearchAll";
        this.searchValue = "";
      }
      this.isPlaceName = false;

      this.currentMapName = params.name;

      let area2 = this.mapNameList.join("/")
      if (this.mapNameList[this.mapNameList.length - 1] !== params.name) {
        area2 += "/" + params.name
      }
      if (this.mapNameList.length == 3) {
        this.getCountryInfos(area2);
      } else {
        this.countryShow = false;
        // this.btnActive = 1;
        this.surnameEchDom = this.$refs.surnameEch;
      }

      if (this.mapIndex >= 2) {
        if (this.isVillage && this.villageName === params.name) {
          this.isVillage = false;
          this.villageName = "";
          this.getMigrate(this.mapNameList.join("/"), false, searchObj);
          this.btnClick(this.btnActive);

          return;
        }
        this.isVillage = true;
        this.villageName = params.name;

        let area = this.mapNameList.join("/")
        if (this.mapNameList[this.mapNameList.length - 1] !== params.name) {
          area += "/" + params.name
        }
        this.getMigrate(
          area,
          false,
          searchObj
        );
        if (this.btnActive === 0) {
          this.infoByAreaObj = await getInfoByArea({
            Area: area,
          });
        } else if (this.btnActive === 1) {
          this.pageIndex1 = 1;
          this.getSurNameCount(area);
        } else if (this.btnActive === 2) {
          this.pageIndex1 = 1;
          this.getBeginPlace(
            area,
            this.mapNameList.length - 1 + 1
          );
        }
        if (flag && !gobackFlag) {
          this.mapNameList.push(params.name);
          flag = !flag;
        }
        this.mapIndex = this.mapNameList.length - 1
        return;
      }
      this.isVillage = false;
      this.villageName = "";
      this.chart.showLoading();
      if (status !== "goBack") {
        if (this.mapNameList.length === 1) {
          this.btnActive = 0;
        }
        if (flag && !gobackFlag) {
          this.mapNameList.push(params.name);
          flag = !flag;
        }
        this.mapIndex++;
      }

      let jsonUrl = `${window.location.origin}/map/${this.mapNameList.join(
        ""
      )}.json`;
      try {
        //去掉村里名包含阿拉伯数字的--开始
        let temGgeoData = await getMap(jsonUrl);
        let temFeatures = [];
        var regex = /[0-9]/;
        temGgeoData.features.forEach((tItem) => {
          if (!regex.test(tItem.properties.V_Name)) {
            temFeatures.push(tItem);
          }
        });
        let geoData = temGgeoData;
        geoData.features = temFeatures;
        //去掉村里名包含阿拉伯数字的--结束
        this.areaList = [
          ...new Set(geoData.features.map((v) => v.properties.name)),
        ];
        this.btnClick(this.btnActive);
        this.getMigrate("", false, searchObj);
        await this.getCount(
          this.mapNameList.join("/"),
          this.mapNameList.length
        );
        echarts.registerMap(params.name, geoData);
        let option = this.loadMap(params.name, this.thermodynamicsList);
        this.chart.setOption(option, true);
        this.chart.hideLoading();
        window.addEventListener("resize", function () {
          this.chart.resize();
        });
      } catch (error) {
        console.log(error);
        this.mapIndex--;
      }
    },
    async getSurNameCount(endPlaceAddress) {
      this.loading1 = true;
      try {
        const res = await getSurNameCount({
          endPlaceAddress,
          pageIndex: this.pageIndex1,
          pageSize: this.pageSize1,
        });
        this.tableData = res.items;
        this.total1 = res.totalCount;
        this.surnameEchInit(res.items, "surname");
      } catch (error) {
        console.log(error);
      } finally {
        this.loading1 = false;
      }
    },
    async getBeginPlace(endPlaceAddress, level) {
      this.loading1 = true;
      try {
        const res = await getBeginPlace({
          endPlaceAddress,
          level,
          pageIndex: this.pageIndex1,
          pageSize: this.pageSize1,
        });
        this.tableDataPlace = res.items;
        this.total1 = res.totalCount;
        this.surnameEchInit(res.items, "beginPlace");
      } catch (error) {
        console.log(error);
      } finally {
        this.loading1 = false;
      }
    },
    async getCountryInfos(areaInfo) {
      this.loading1 = true;
      try {
        const res = await getCountryInfos({
          area: areaInfo,
        });
        this.countryInfos = res;
        this.countryShow = true;
        // this.btnActive = 0;
      } catch (error) {
        // console.log(error);
        // this.btnActive = 1;
        this.surnameEchDom = this.$refs.surnameEch;
        this.countryShow = false;
      } finally {
        this.loading1 = false;
      }
    },
    async getMigrate(EndPlace = "", state = false, searchObj = null) {
      this.loading = true;
      let searchQuery = {};
      // 点击搜索
      if (state) {
        var queryArea = this.mapNameList.join("/");
        if (
          this.mapNameList.length == 3 &&
          this.mapNameList[2] != this.currentMapName
        ) {
          queryArea += "/" + this.currentMapName;
        }
        if (this.searchType == "EndPlace") {
          searchQuery = {
            PageIndex: this.pageIndex,
            PageSize: this.pageSize,
            [this.searchType]: this.searchValue,
          };
        } else {
          searchQuery = {
            PageIndex: this.pageIndex,
            PageSize: this.pageSize,
            [this.searchType]: this.searchValue,
            EndPlace: queryArea,
          };
        }
        // 点击地图
      } else {
        if (searchObj) {
          searchQuery = {
            PageIndex: this.pageIndex,
            PageSize: this.pageSize,
            [this.searchType]: this.searchValue,
            EndPlace: EndPlace || this.mapNameList.join("/"),
            ...searchObj,
          };
        } else {
          searchQuery = {
            PageIndex: this.pageIndex,
            PageSize: this.pageSize,
            [this.searchType]: this.searchValue,
            EndPlace: EndPlace || this.mapNameList.join("/"),
          };
        }
      }
      try {
        const res = await getMigrate(searchQuery);
        this.listInfo = res.items;
        this.total = res.totalCount;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    viewInfo(e) {
      this.mapDialog = true;
      this.migrationPointsList = e.retrunMigrates ? e.retrunMigrates : [];
      this.allPointsList = e.returnLocations ? e.returnLocations : [];
      this.$nextTick(() => {
        this.$refs.map.$refs.myMap.init();
      });
    },
    async viewPic(e) {
      try {
        const res = await getDetails(e.bookID);
        this.infoObj = {
          title: res.title,
          publisher: res.publisher,
          dateOfPublication: res.dateOfPublication,
          copies: res.copies,
          ancestor: res.ancestor,
          owner: res.owner,
          publisher: res.publisher,
          id: res.id,
        };
        this.pictureFullName = e.pictureFullName;
        this.genealogyOCRInfoID = e.genealogyOCRInfoID
          .replace("；", ";")
          .split(";")[0];
        this.infoMrigObj = e;
        this.isPicShow = true;
      } catch (error) {
        console.log(error);
      }
    },
    handleCurrentChange(index) {
      this.pageIndex = index;
      if (this.searchValue) {
        this.getMigrate("", true);
      } else if (this.villageName) {
        this.getMigrate(this.mapNameList.join("/") + "/" + this.villageName);
      } else {
        this.getMigrate();
      }
    },
    handleCurrentChange1(index) {
      this.pageIndex1 = index;
      if (this.btnActive === 1) {
        this.getSurNameCount(this.mapNameList.join("/"));
      } else if (this.btnActive === 2) {
        this.getBeginPlace(
          this.mapNameList.join("/"),
          this.mapNameList.length - 1
        );
      }
    },
    async btnClick(index) {
      this.btnActive = index;
      let name = "";
      let mapIndex = "";
      // if (this.isVillage) {
      //   name = this.mapNameList.join("/") + "/" + this.villageName;
      //   mapIndex = this.mapNameList.length - 1 + 1;
      // } else {
      name = this.mapNameList.join("/");
      mapIndex = this.mapNameList.length - 1;
      // }
      if (index === 0) {
        const res = await getInfoByArea({
          Area: this.isVillage ? name : this.mapNameList.join("/"),
        });
        this.infoByAreaObj = res;
      } else if (index === 1) {
        this.pageIndex1 = 1;
        this.getSurNameCount(name);
      } else if (index === 2) {
        this.pageIndex1 = 1;
        this.getBeginPlace(name, mapIndex);
      } else if (index === 3) {
        // this.imgUrl = "";
        console.log(this.mapNameList);
      }
    },
    btnClick1(index) {
      this.btnActive2 = index;
      this.GetCountrySameNameInfo(index);
    },
    surnameEchInit(data, name) {
      if (this.surnameEchChart) {
        this.surnameEchChart.dispose();
      }
      this.surnameEchChart = echarts.init(this.surnameEchDom);
      let option = {
        tooltip: {
          trigger: "item",
          formatter: "{b}: {c}({d}%)",
        },

        series: [
          {
            type: "pie",
            radius: "50%",
            // label: {
            //   normal: {
            //     show: true,
            //     formatter: "{b}: {c}({d}%)", //自定义显示格式(b:name, c:value, d:百分比)
            //   },
            // },
            data: data.map((item) => ({
              name: item[name],
              value: item.count,
            })),
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0,0,0,0.5)",
              },
            },
          },
        ],
      };
      this.surnameEchChart.setOption(option, true);
    },
    viewSurname(data, name) {
      // if (data.length) {
      //   this.surnameEchInit(data, "beginPlace");
      // } else {
      //   this.surnameEchInit(this.tableData, "surname");
      // }
      this.dialogSurname = name;
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.dialogChartInit(data, "echart1");
      });
    },
    dialogChartInit(data, id) {
      let chat = echarts.init(document.getElementById(id));
      // 解决第二次进入弹窗的bug
      if (chat) {
        chat.dispose();
      }
      chat = echarts.init(document.getElementById(id));
      let option = {
        tooltip: {
          trigger: "item",
          formatter: "{b}: {c}({d}%)",
        },

        series: [
          {
            type: "pie",
            radius: "50%",
            data: data.map((item) => ({
              name: item.beginPlace,
              value: item.count,
            })),
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
      chat.setOption(option, true);
      chat.on("click", (params) => {
        this.num += 1;
        this.placeAndSurname(params.name);
      });
    },
    viewPlace(data) {
      if (data.length) {
        this.surnameEchInit(data, "surname");
        this.isPlaceName = true;
      } else {
        this.surnameEchInit(this.tableDataPlace, "beginPlace");
        this.isPlaceName = false;
      }
    },
    async getCount(placeName, level) {
      const res = await getCount({ placeName, level });
      this.thermodynamicsList = res;
      this.areaEchartInit(res);
    },
    areaEchartInit(data) {
      // if (this.areaEchart) {
      //   this.areaEchart.dispose();
      // }
      // this.areaEchart = echarts.init(this.areaDom);
      // let option = {
      //   tooltip: {
      //     trigger: "item",
      //     formatter: "{b}: {c}({d}%)",
      //   },
      //   series: [
      //     {
      //       type: "pie",
      //       radius: "50%",
      //       data: data.map((item) => ({
      //         name: item.placeName,
      //         value: item.count,
      //       })),
      //       emphasis: {
      //         itemStyle: {
      //           shadowBlur: 10,
      //           shadowOffsetX: 0,
      //           shadowColor: "rgba(0, 0, 0, 0.5)",
      //         },
      //       },
      //     },
      //   ],
      // };
      // this.areaEchart.setOption(option, true);
    },
    placeClick(name) {
      this.mapClick({ name });
    },
    async goBack() {
      this.countryShow = false;

      this.pageIndex = 1;
      this.isVillage = false;
      this.villageName = "";
      if (this.mapNameList.length === 1) return;
      this.mapIndex -= 1;
      this.mapNameList.pop();
      if (this.mapNameList.length <= 1) {
        this.btnActive = 1;
        this.areaList = [
          ...new Set(taiWan.features.map((v) => v.properties.name)),
        ];
        this.getSurNameCount("台湾省");
        this.getMigrate();
        await this.getCount("台湾省", 1);
        this.init();
      } else {
        this.mapClick(
          { name: this.mapNameList[this.mapNameList.length - 1] },
          "goBack",
          null,
          true
        );
      }
    },
    // 点击地区获取详情
    async placeAndSurname(beginPlaceAddress) {
      const res = await placeAndSurname({
        surname: this.dialogSurname,
        endPlaceAddress: this.mapNameList.join("/"),
        beginPlaceAddress,
      });
      this.dialogChartInit(res, "echart" + this.num);
    },
    dialogClose() {
      this.dialogVisible = false;
      this.num = 1;
      this.dialogSurname = "";
    },
  },
};
</script>

<style scoped lang="scss">
table {
  width: 100%;
  // border-collapse: collapse;
  // text-align: center;

  .td1 {
    line-height: 45px;
    padding-left: 5px;
    width: 236px;
    font-size: 14px;
  }
  .td2 {
    min-height: 36px;
    padding: 8px;
    font-size: 14px;
    font-family: 新宋体;
    line-height: 140%;

    span {
      padding-right: 20px;
      cursor: pointer;
      color: #4f81bd;
    }
    span:hover {
      color: red;
    }
  }
}
table td,
table th {
  border: 1px solid #cad9ea;
  color: #666;
  height: 30px;
}
table tr:nth-child(odd) {
  background: #fff;
}
table tr:nth-child(even) {
  background: #f5fafa;
}
::v-deep .el-input__inner {
  border-radius: 0;
}

::v-deep .el-button {
  border-radius: 0;
}

.container {
  width: 100%;
  height: calc(100vh - 65px);

  .tisTop {
    position: absolute;
    z-index: 99999999999;
    width: 500px;
    top: 100px;
    left: 30px;
    background: #fff;
    padding: 12px;
    color: #606266;
    border-radius: 4px;
    line-height: 1.4;
    text-align: justify;
    font-size: 14px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    word-break: break-all;
    padding: 0;

    .title {
      position: static;
      top: 0;
      left: 0;
      padding: 0 20px;
      height: 50px;
      line-height: 50px;
      background: #73c0de;
      color: #fff;
      font-size: 18px;
      cursor: move;
      display: flex;
      justify-content: space-between;

      .close {
        cursor: pointer;
      }
    }

    .content {
      padding: 5px 20px;
      height: 700px;
      overflow: auto;
    }
  }

  .surnameDialog {
    ::v-deep .el-dialog {
      margin-top: 10vh !important;
    }
  }

  ::v-deep .el-dialog__header {
    background: rgb(126, 206, 244);
    color: #fff;

    .el-dialog__close,
    .el-dialog__title {
      color: #fff;
      font-size: 24px;
    }
  }

  #dlog {
    ::v-deep .el-dialog__header {
      background: rgb(126, 206, 244);
      color: #fff;
      padding: 20px 20px 10px;
      height: 54px;

      .el-dialog__close,
      .el-dialog__title {
        color: #fff;
        font-size: 24px;
      }
    }

    .dialog_box {
      display: flex;
      height: 60vh;

      .left {
        height: 60vh;
        display: flex;
        overflow: hidden;
        justify-content: center;
        flex: 1;
        border-right: 1px solid #eee;
      }

      .right {
        padding: 0 20px;
        width: 30%;
        height: 100%;
        overflow: auto;
      }
    }
  }

  .echartBox {
    display: flex;
    flex-wrap: wrap;
    overflow: auto;
    height: 70vh !important;

    .echart {
      margin: 30px;
      width: 400px;
      height: 400px;
      border: 1px solid #eee;
    }
  }
}

.box {
  height: 100%;
  display: flex;

  .left {
    width: 320px !important;
    height: 100%;
    border-right: 1px solid rgb(209, 202, 161);

    .top {
      img {
        vertical-align: middle;
        width: 48px;
        height: 48px;
      }

      span {
        margin-left: 10px;
        color: rgb(129, 134, 217);
        padding-right: 20px;
        padding-bottom: 5px;
        font-size: 22px;
        border-bottom: 2px solid rgb(69, 198, 238);
      }
    }

    .mapName {
      width: 300px;
      z-index: 99;
      display: flex;
      padding: 10px;
      color: #4f81bd;
      font-size: 16px;
      border-bottom: 1px solid #4f81bd;
      font-weight: 700;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      span {
        padding: 5px;
      }
    }

    .middle {
      display: flex;
      flex-wrap: wrap;
      padding-left: 50px;
      height: 400px;
      overflow: auto;
      padding-top: 30px;
      align-content: flex-start;

      .item {
        cursor: pointer;
        padding: 10px;
        width: 100px;

        img {
          vertical-align: middle;
          transform: rotate(90deg);
          width: 18px;
          height: 18px;
        }

        span {
          margin-left: 5px;
          color: #4f81bd;
          font-size: 14px;
        }
      }

      .item:hover span {
        color: red;
      }
    }

    .bottom {
      width: 100%;
      margin-top: 35px;
      border-top: 1px solid #4f81bd;

      .areaEchart {
        width: 100%;
        height: 300px;
      }

      p {
        color: #4f81bd;
        font-size: 18px;
        text-align: center;
      }
    }
  }

  .right {
    display: flex;
    flex: 1;

    .map {
      width: 50%;
      height: 100%;
      // padding: 10px 10px 20px 10px;
      position: relative;

      .button {
        position: absolute;
        top: 10px;
        left: 10px;
        padding: 10px 20px;
        background: #73c0de;
        color: #fff;
        cursor: pointer;
        border-radius: 10px;
      }

      .echartMap {
        // margin-top: 15px;
        height: 100%;
      }
    }

    .table {
      // padding: 0 10px 0 0;

      width: 50%;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      .toppage {
        width: 100%;
        background: #fff;
        text-align: center;
      }

      .two {
        width: 100%;

        .title {
          height: 40px;
          line-height: 40px;
          display: flex;
          background: rgb(126, 206, 244);

          div {
            padding: 0 10px;
            background: rgb(126, 206, 244);
            cursor: pointer;
          }

          .active {
            background: #fac858;
            color: red;
          }
        }
        .test {
          /* WebKit 内核浏览器 */
          ::-webkit-scrollbar {
            width: 6px;
            height: 6px;
            background-color: #f2f2f2;
          }

          ::-webkit-scrollbar-thumb {
            background: #9cc3ce;
            border-radius: 10px;
          }

          /* 其他浏览器 */
          * {
            scrollbar-width: auto;
            scrollbar-color: #9cc3ce #f2f2f2;
          }

          *::-webkit-scrollbar {
            width: 6px;
            height: 6px;
          }

          *::-webkit-scrollbar-thumb {
            background: #9cc3ce;
            border-radius: 10px;
          }

          *::-webkit-scrollbar-track {
            background-color: #f2f2f2;
          }
          .name_place1 {
            height: 370px;
            overflow: auto;
            border-left: 1px solid #fff;
            display: flex;
            flex-direction: column;
            position: relative;
          }
        }

        .name_place {
          width: 100%;
          display: flex;
          // height: 100%;

          table {
            width: 100%;
            // border-collapse: collapse;
            // text-align: center;

            .td1 {
              line-height: 45px;
              padding-left: 5px;
              width: 236px;
              font-size: 14px;
            }
            .td2 {
              min-height: 36px;
              padding: 8px;
              font-size: 14px;
              font-family: 新宋体;
              line-height: 140%;

              span {
                padding-right: 20px;
                cursor: pointer;
                color: #4f81bd;
              }
              span:hover {
                color: red;
              }
            }
          }
          table td,
          table th {
            border: 1px solid #cad9ea;
            color: #666;
            height: 30px;
          }
          table tr:nth-child(odd) {
            background: #fff;
          }
          table tr:nth-child(even) {
            background: #f5fafa;
          }

          .left {
            width: 50%;
          }

          .right {
            width: 50%;
            display: flex;
            // justify-content: center;
            align-items: center;
            flex-direction: column;

            .surnameEch {
              width: 340px;
              height: 340px;
            }

            span {
              color: #4f81bd;
              font-size: 18px;
            }
          }
        }
      }
    }
  }
}
</style>