<template>
  <el-dialog
    title="迁徙图"
    :visible="mapDialog"
    :fullscreen="true"
    width="90%"
    @close="$emit('update:mapDialog', false)"
  >
    <arc-map v-bind="$attrs" ref="myMap"></arc-map>
  </el-dialog>
</template>

<script>
import arcMap from "../../arcgisMap/index.vue";

export default {
  components: {
    arcMap,
  },
  props: {
    mapDialog: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .el-dialog__body {
  padding: 0;
}
::v-deep .el-dialog__header {
  background: rgb(126, 206, 244);
  color: #fff;
  .el-dialog__close,
  .el-dialog__title {
    color: #fff;
    font-size: 24px;
  }
}
</style>